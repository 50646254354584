<template>
    <div class="empty-state-container">
        <svg v-if="icon" class="icon" viewBox="0 0 24 24">
            <use :xlink:href="icon" />
        </svg>
        <div class="empty-state-header">{{ message }}</div>
        <p class="sub-message">
            {{ subMessage }}
        </p>
    </div>
</template>

<script>
export default {
    name: 'EmptyState',
    props: {
        icon: {
            type: String,
            required: false
        },
        message: {
            type: String,
            default: 'Nothing to see here...yet!'
        },
        subMessage: {
            type: String,
            required: false
        }
    }
};
</script>

<style lang="scss" scoped>
@import '~@/styles/variables';

.empty-state-container {
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    width: 70%;
    margin: 0 auto;
    text-align: center;
    .icon {
        width: 32px;
        height: 32px;
        color: $dark-gray;
        fill: none;
        margin-bottom: 8px;
    }
    .empty-state-header {
        font-size: 1.4rem;
        font-weight: 700;
        color: $dark-gray;
        margin-bottom: 4px;
    }
    p {
        line-height: 1.6rem;
    }
}
</style>
