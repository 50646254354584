'use strict';

import axios from 'axios';
import { strictEqual } from 'assert';
import { trimAndLower } from './string-helpers';
import * as Api from '../api/index';

let dbFeatureFlags = [];

const featureFlagTypes = {
    reactiveChatbotFeature: 'reactive_chatbot_admin_ui_feature',
    webChatbotFeature: 'WEB_CHATBOT_FEATURE',
    webChatbotSettingsPage: 'WEB_CHATBOT_SETTINGS_PAGE',
    webChatbotLeadCaptureSettings: 'WEB_CHATBOT_LEAD_CAPTURE_SETTINGS',
    studentDetailChatHistory: 'STUDENT_DETAIL_CHAT_HISTORY'
};

const isDbFlagEnabled = async featureFlag => {
    strictEqual(typeof featureFlag, 'string');

    const url = Api.getv2ApiBase('/feature-flag');
    await axios
        .get(url, Api.getOptions())
        .then(response => {
            if (Array.isArray(response.data)) {
                dbFeatureFlags = response.data;
            }
        })
        .catch(() => {
            console.log('Unable to load feature flags');
        });

    return dbFeatureFlags.find(flag => {
        if (flag && flag.name) {
            return trimAndLower(flag.name) === trimAndLower(featureFlag);
        }
    });
};

const isUIFlagDefined = featureFlag => {
    strictEqual(typeof featureFlag, 'string');

    return process.env.hasOwnProperty(featureFlag);
};

const isBooleanUIFlagEnabled = featureFlag => {
    strictEqual(typeof featureFlag, 'string');

    if (isUIFlagDefined(featureFlag)) {
        return process.env[featureFlag] === 'true';
    }

    return undefined;
};

const isUserUIFlagEnabled = (featureFlag, userEmail) => {
    strictEqual(typeof featureFlag, 'string');
    strictEqual(typeof userEmail, 'string');

    if (isUIFlagDefined(featureFlag) && featureFlag.endsWith('_ENABLED_FOR_USERS')) {
        const users = process.env[featureFlag].split(',').map(email => email.toLowerCase());
        return users.includes(userEmail.toLowerCase());
    }

    return undefined;
};

export const isFeatureFlagEnabled = async featureFlag => {
    try {
        strictEqual(typeof featureFlag, 'string');

        // first look for a UI only feature flag
        const uiFlag = isBooleanUIFlagEnabled(featureFlag);

        if (typeof uiFlag === 'boolean') {
            return uiFlag;
        }

        // next, look for a DB driven feature flag
        const dbFlag = await isDbFlagEnabled(featureFlag);

        if (typeof dbFlag === 'boolean') {
            return dbFlag;
        }

        // if the flag is not found anywhere, then return false: the flag is
        // not enabled
        return false;
    } catch (error) {
        console.error(error);
        return false;
    }
};

// To use a feature flag based on a user's email, define the environment variable with a suffix of '_ENABLED_FOR_USERS'.
// Otherwise, the flag is assumed to be a boolean.
// User flag example: VUE_APP_MY_FEATURE_ENABLED_FOR_USERS=email1@edsights.io,email2@edsights.io
// Boolean flag example: VUE_APP_MY_FEATURE_ENABLED=true
export const isUIFeatureFlagEnabled = (featureFlag, userEmail) => {
    try {
        strictEqual(typeof featureFlag, 'string');

        if (typeof userEmail === 'string') {
            const userFlagEnabled = isUserUIFlagEnabled(featureFlag, userEmail);
            return typeof userFlagEnabled === 'boolean' ? userFlagEnabled : false;
        }

        const booleanFlagEnabled = isBooleanUIFlagEnabled(featureFlag);
        return typeof booleanFlagEnabled === 'boolean' ? booleanFlagEnabled : false;
    } catch (error) {
        console.error(error);
        return false;
    }
};

export const isDbFeatureFlagEnabled = async featureFlag => {
    try {
        strictEqual(typeof featureFlag, 'string');

        // next, look for a DB driven feature flag
        const dbFlag = await isDbFlagEnabled(featureFlag);

        if (typeof dbFlag === 'boolean') {
            return dbFlag;
        }

        return false;
    } catch (error) {
        console.error(error);
        return false;
    }
};

export const isExperimentalAccessibilityEnabled = schoolId => {
    strictEqual(typeof schoolId, 'string', 'schoolId must be a name');
    const schools = [
        // EdSights Training University (only exists in production at the moment):
        '9c5d5a5b-9b2a-48b1-aa0d-3d8840727c5e'
    ];

    // Confirm it's enabled for the environment and school.
    return (
        isUIFeatureFlagEnabled('VUE_APP_FEATURE_FLAG_EXPERIMENTAL_ACCESSIBILITY') &&
        schools.includes(schoolId)
    );
};

export const isDbSchoolFeatureFlagEnabled = (schoolId, featureFlagName) => {
    strictEqual(typeof schoolId, 'string');
    strictEqual(typeof featureFlagName, 'string');

    const url = Api.getv2ApiBase(
        `/is-feature-flag-enabled-for-school/${featureFlagName}/${schoolId}`
    );
    return axios
        .get(url, Api.getOptions())
        .then(response => {
            return response.data;
        })
        .catch(() => {
            console.log(`Unable to load school specific feature flag: ${featureFlagName}`);
        });
};

export const isNextGenAIChatbotEnabled = async schoolId => {
    strictEqual(typeof schoolId, 'string', 'schoolId must be a valid id');

    const nextGenAIChatbotEnabled = await isDbSchoolFeatureFlagEnabled(
        schoolId,
        featureFlagTypes.reactiveChatbotFeature
    );
    return nextGenAIChatbotEnabled;
};

export const isWebChatbotEnabled = schoolId => {
    strictEqual(typeof schoolId, 'string', 'schoolId must be a string');

    return isDbSchoolFeatureFlagEnabled(schoolId, featureFlagTypes.webChatbotFeature);
};

export const isWebChatbotSettingsPageEnabled = schoolId => {
    strictEqual(typeof schoolId, 'string', 'schoolId must be a string');

    return isDbSchoolFeatureFlagEnabled(schoolId, featureFlagTypes.webChatbotSettingsPage);
};

export const isWebChatbotLeadCaptureSettingsEnabled = schoolId => {
    strictEqual(typeof schoolId, 'string', 'schoolId must be a string');

    return isDbSchoolFeatureFlagEnabled(schoolId, featureFlagTypes.webChatbotLeadCaptureSettings);
};

export const isStudentDetailChatHistoryEnabled = schoolId => {
    strictEqual(typeof schoolId, 'string', 'schoolId must be a string');

    return isDbSchoolFeatureFlagEnabled(schoolId, featureFlagTypes.studentDetailChatHistory);
};
