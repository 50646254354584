<script setup>
import { computed, ref, defineProps, defineEmits, onMounted } from 'vue';
import { BaseDrawer, BaseChatMessageList, BaseButton } from '@edsights/ui-core';
import LoadingSpinner from '@/components-deprecated/LoadingSpinner.vue';
import v2CollectionManager from '@/services/v2CollectionManager';

const props = defineProps({
    studentId: {
        type: String,
        required: true
    }
});

const emit = defineEmits(['close']);

const messagesCollection = ref(
    v2CollectionManager.create({
        listType: 'studentConversation',
        paginationOptions: {
            size: 25
        },
        errorHandlerOptions: {
            enableAlert: true,
            overrideMessage:
                "There was a problem loading the student's conversation. Please try again.",
            rethrow: false
        }
    })
);

const formattedMessages = computed(() => {
    return messagesCollection.value.list.map(message => ({
        id: message.id,
        text: message.text,
        date: new Date(message.datetimeCreated),
        userId: message.messageType === 'bot' ? 'bot' : props.studentId,
        userName: message.messageType === 'bot' ? botName.value : studentName.value
    }));
});

const studentName = computed(() => {
    return messagesCollection.value.listInfo.studentName || '';
});

const botName = computed(() => {
    return messagesCollection.value.listInfo.botName || '';
});

const messagesCollectionLoading = computed(() => {
    return messagesCollection.value.refreshing;
});

const messageCollectionLoadingNextPage = computed(() => {
    return messagesCollection.value.loadingNextPage;
});

const messageCollectionHasNextPage = computed(() => {
    return messagesCollection.value.pagination.hasNextPage;
});

const loadInitialMessages = async () => {
    messagesCollection.value.filters.studentId = props.studentId;
    await messagesCollection.value.refresh();
};

const loadOlderMessages = async () => {
    if (messageCollectionHasNextPage.value && !messageCollectionLoadingNextPage.value) {
        await messagesCollection.value.addNextPage();
    }
};

const close = () => {
    emit('close');
};

onMounted(() => {
    loadInitialMessages();
});
</script>

<template>
    <BaseDrawer
        class="student-chat-history-drawer"
        :header="`Chat History${studentName ? ` for ${studentName}` : ''}`"
        width="430px"
        @close="close"
    >
        <div class="drawer-content">
            <LoadingSpinner v-if="messagesCollectionLoading" />
            <BaseChatMessageList
                v-else
                class="message-list"
                :outgoingUserId="studentId"
                :messages="formattedMessages"
                @scrolledToTop="loadOlderMessages"
            >
                <template #before-messages>
                    <p
                        v-if="
                            !messagesCollectionLoading &&
                                !messageCollectionLoadingNextPage &&
                                !messageCollectionHasNextPage
                        "
                        class="history-statement"
                    >
                        History is only visible since August 1, 2024. For questions on previous
                        history, reach out to your EdSights point of contact.
                    </p>
                    <LoadingSpinner v-if="messageCollectionLoadingNextPage" />
                </template>
            </BaseChatMessageList>
        </div>

        <template #footer>
            <div class="drawer-footer">
                <BaseButton :style="'secondary'" label="Close" @click="close" />
            </div>
        </template>
    </BaseDrawer>
</template>

<style scoped lang="scss">
.student-chat-history-drawer {
    .drawer-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 100%;

        .message-list {
            width: 100%;
            height: 100%;
            padding-top: 20px;

            .history-statement {
                font-size: 0.83rem;
                text-align: center;
                padding-bottom: 20px;
            }
        }
    }

    .drawer-footer {
        display: flex;
        justify-content: flex-end;
    }
}
</style>
