<template>
    <div class="faq">
        <div class="faq__selected">
            <BaseCheckbox :modelValue="selected" @update:modelValue="toggleSelected" />
        </div>
        <div class="faq__question">
            {{ faq.questionText }}
        </div>
        <div class="faq__answer">
            {{ faq.answerText }}
        </div>
        <div class="faq__topic">
            {{ faq.topic }}
        </div>
        <div class="faq__subtopic">
            {{ faq.subtopic }}
        </div>

        <div class="faq__updated">
            {{ faq.friendlyUpdatedAt }}
        </div>

        <div class="faq__status">
            <LoadingSpinner v-if="toggleLoading" />
            <div v-else>
                <InputSwitch
                    :modelValue="faq.isEnabled"
                    :label="faq.isEnabled ? 'Approved' : 'Pending'"
                    @update:modelValue="setEnabled"
                    ariaLabel="Toggle FAQ as approved or pending"
                />
            </div>
        </div>

        <div class="faq__action">
            <div class="action_container">
                <BaseIcon class="action-button" icon="pencil" @click="onClickEditFaq" />
                <BaseIcon class="action-button" icon="trash" @click="onClickRemoveFaq" />
            </div>
        </div>
    </div>
</template>

<script>
import { BaseIcon, BaseCheckbox } from '@edsights/ui-core';
import * as FAQv2Api from '@/api/faqs-v2';
import LoadingSpinner from '@/components-deprecated/LoadingSpinner.vue';
import InputSwitch from '@/components-deprecated/global/v2/InputSwitch';

export default {
    name: 'Faqv2ListItem',
    components: {
        BaseIcon,
        LoadingSpinner,
        InputSwitch,
        BaseCheckbox
    },
    data() {
        return {
            toggleLoading: false
        };
    },
    props: {
        faq: {
            type: Object,
            required: true
        },
        selected: {
            type: Boolean,
            default: false
        }
    },
    created() {},
    methods: {
        onClickEditFaq() {
            this.$modal.show('modal-add-edit-faq-v2', { faq: this.faq });
        },
        onClickRemoveFaq() {
            this.$modal.show('modal-remove-faq-v2', { faq: this.faq });
        },
        async setEnabled(enabled) {
            this.toggleLoading = true;
            try {
                await FAQv2Api.setEnabled({
                    faqId: this.faq.id,
                    enabled,
                    errorHandlerOptions: {
                        rethrow: true
                    }
                });
                this.$emit('updateFaqStatus', this.faq.id, enabled);
                this.toggleLoading = false;
            } catch (error) {
                this.toggleLoading = false;
            }
        },
        toggleSelected(value) {
            this.$emit('updateFaqSelected', this.faq.id, value);
        }
    }
};
</script>

<style lang="scss" scoped>
@import '~@/styles/variables.scss';

.action-button {
    all: unset;
    display: inline-block;
    cursor: pointer;
}

.faq {
    position: relative;
    display: table-row;
    margin: 8px 0;

    .faq__selected {
        padding-top: 0px;
        padding-left: 5px;
        width: 20px;
        padding: 5px;
    }

    .faq__question {
        width: 250px;
        padding-right: 15px;
    }

    .faq__answer {
        max-width: 300px;
        padding-right: 15px;
    }

    .faq__topic {
        width: 112px;
        padding-right: 15px;
    }

    .faq__subtopic {
        width: 130px;
    }

    .faq__updated {
        width: 110px;
    }

    .faq__status {
        width: 120px;
    }

    .faq-action-icon {
        margin-top: 5px;
        width: 20px;
        height: 20px;
    }

    .faq__action {
        width: 70px;
    }
    .action_container {
        display: grid;
        grid-template-columns: 1fr 1fr;
    }

    & > div {
        padding: 10px 6px;
        word-wrap: break-word;
        display: table-cell;
        vertical-align: middle;
    }
}

.action--button {
    color: $primary-brand-color;
}
</style>
